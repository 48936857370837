import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ActivityPage = () => (
  <Layout>
    <SEO title="Nearby Activities" />
    <h2>Nearby Places of Interest</h2>
    <div>
    <ul>
      <li>Hospitals</li>
      <ul>
        <li>Tata Medical Hospital, Newtown</li>
        <li>Ohio Hospital, Newtown</li>
        <li>Sankara Nethralaya New Town</li>
        <li>Chittaranjan National Cancer Institute, Newtown</li>
        <li>AMRI, Salt Lake</li>
      </ul>
      <li>Sports</li>
      <ul>
        <li>Golf Coaching / Driving Range - Talk to us for more details!</li>
        <li>Yoga near Bisarjan Ghat</li>
      </ul>
      <li>Malls</li>
      <ul>
        <li>Axis Mall, Newtown</li>
        <li>Central Mall, Newtown</li>
        <li>City Center 2, Newtown</li>
        <li>City Center 1, Salt Lake</li>
      </ul>
      <li>Movie Theatres</li>
      <ul>
        <li>Bioscope: Axis Mall, Newtown(Rajarhat)</li>
        <li>Carnival: Salt Lake</li>
        <li>INOX: City Center 1, Salt Lake</li>
        <li>INOX: City Center 2, Newtown(Rajarhat)</li>
        <li>RDB Cinemas: Salt Lake</li>
      </ul>
      <li>Others</li>
      <ul>
        <li>Major IT Companies(TCS, Cognizant, Cap Gemini, Accenture) within a 15 minute radius</li>
        <li>IT hubs like DLF-1, DLF-2</li>
        <li>Eco Park</li>
        <li>Biswa Bangla Convention Centre</li>
      </ul>
    </ul>
    </div>
  </Layout>
)

export default ActivityPage
